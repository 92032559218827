import storageAvailable from './storage-available';

export default (key: string, val: string, expires?) => {

  if (typeof expires == 'undefined' && storageAvailable('localStorage')) {
    localStorage.setItem(key, val);
  } else {
    Cookies.set(key, val, { expires: expires });
  }

};
